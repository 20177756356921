export const MenuItems = [
  {
    title: "Home",
    url: "/",
    cName: "nav-links",
  },
  {
    title: "About",
    url: "/about",
    cName: "nav-links",
  },
  {
    title: "How it Works",
    url: "/how-it-works",
    cName: "nav-links",
  },
  {
    title: "Pricing",
    url: "/pricing",
    cName: "nav-links",
  },
];
